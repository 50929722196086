import { Store } from 'types/stores.types';

export enum organizationAlliesEnum {
  HOSPITARIA = 'Hospitaria',
  FLORA_FERTILIDAD = 'Flora Fertilidad',
  SWISS = 'Swiss Hospital',
  PRIMER_FRENTE = 'Primer Frente',
  BALAAM = 'Balaam',
  OSSIS = 'Ossis',
  BMSA = 'BMSA',
  CODEFAR = 'CODEFAR',
  KONIGSBERG = 'Konigsberg',
  A5 = 'A5 Express'
}

export const HospitariaCedis = [
  'Sucursal Monterrey',
  'Hospitaria',
  'Hospitaria CAIH',
  'Hospitaria Vitau',
];

const FloraOperatorCedis = [
  'Sucursal Monterrey',
  'Flora Fertilidad',
]

const SwissOperatorCedis = [
  'Sucursal Monterrey',
  'Swiss Hospital',
]

const PrimerFrenteCedis = [
  'Sucursal Monterrey',
  'Primer Frente',
];

const OssisCedis = [
  'Sucursal Monterrey',
  'Ossis',
];

const CodefarCedis = [
  'Sucursal Monterrey',
  'CODEFAR',
];

const A5Cedis = [
  'Sucursal Monterrey',
  'A5 Express',
];

const useOperatorCedis = (
  stores: Store[] | undefined,
  userOrganization: organizationAlliesEnum | undefined) => {

  switch (userOrganization) {
    case organizationAlliesEnum.HOSPITARIA:
      return stores?.filter((store) => HospitariaCedis.includes(store.name))

    case organizationAlliesEnum.FLORA_FERTILIDAD:
      return stores?.filter((store) => FloraOperatorCedis.includes(store.name))

    case organizationAlliesEnum.SWISS:
      return stores?.filter((store) => SwissOperatorCedis.includes(store.name))

    case organizationAlliesEnum.PRIMER_FRENTE:
      return stores?.filter((store) => PrimerFrenteCedis.includes(store.name))

    case organizationAlliesEnum.OSSIS:
      return stores?.filter((store) => OssisCedis.includes(store.name))

    case organizationAlliesEnum.CODEFAR:
      return stores?.filter((store) => CodefarCedis.includes(store.name))

    case organizationAlliesEnum.A5:
      return stores?.filter((store) => A5Cedis.includes(store.name))

    default:
      return []
  }
}

export default useOperatorCedis

// "Flora Fertilidad",
// "Sucursal Monterrey",
// "Mercado Libre FULL",
// "Ossis",
// "Hospitaria",
// "Amazon FULL",
// "Amazon Seller Flex CDMX",
// "Amazon Seller Flex MTY",
// "Hospitaria CAIH",
// "Coppel",
// "Sucursal Cd. Mx.",
// "Hospitaria Vitau",
// "Swiss Hospital",
// "Primer Frente",
